import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const AnimateTutorialsPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://www.youtube.com/playlist?list=PLoUOQU--JyXUkOkDFGSgjlamsOdxUly5A')
        }, 100)
        

        }, []),

        <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>SketchWow Animate Tutorial</>}
      
    />
  </Layout>
);

export default AnimateTutorialsPage;
